<template>
    <Tree :value="nodes" v-show="showTree" selectionMode="checkbox" v-model:selectionKeys="selectedKeys" @click="onClick" />
    <ProgressSpinner class="progressSpinner" strokeWidth="8" v-if="loading" />
</template>

<script>
import Tree from 'primevue/tree';
import { BoroughService, BuildingService, DistrictService, LocationService, VisitLocationService } from '../apis';
import { toasterTime, locationTreePrefix } from './constants';
import { groupBy } from './utils';
import { USER_ROLE, unassignedDistrict, boroughOther } from '../shared/constants';
import ProgressSpinner from 'primevue/progressspinner';

export default {
    name: 'LocationSelection',
    components: { Tree, ProgressSpinner },
    props: {
        showTree: Boolean,
        selectedLocations: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            nodes: [],
            selectedKeys: this.selectedLocations,
            locations: new Map(),
            loading: true,
            visitLocations: []
        };
    },
    emits: ['selectTree', 'locationsSet', 'loading'],
    created() {
        if (this.userDetails.role == USER_ROLE.BUILDING_MANAGER) {
            this.setTreeNodeBuildingManager();
        } else {
            this.setupTreeNodes();
        }
    },
    computed: {
        userDetails() {
            return this.$store.getters.userDetails;
        }
    },
    methods: {
        async setupTreeNodes() {
            this.emitLoading(true);
            this.loading = true;
            const filterCondition = { isInScope: { matchMode: '==', value: 1 } };
            const nodemap = new Map();
            const [districtValue, buildingValue, locationValue, boroughValue] = await Promise.all([
                DistrictService.getAll(filterCondition),
                BuildingService.getAll(filterCondition),
                LocationService.getAll(filterCondition),
                BoroughService.getAll(filterCondition)
            ]);
            // Unassigned locations are now hidden from filters from all the screens. Task - NDCVE-916
            boroughValue.forEach(boroughId => {
                const boroughMap = new Map([['id', boroughId.id]]);
                boroughMap.set('name', boroughId.name);
                nodemap.set(boroughId, boroughMap);
                districtValue.filter(x=>x.boroughId== boroughId.id).forEach(districtId => {
                    const districtMap = new Map([['id', districtId.id]]);
                    districtMap.set('name', districtId.name);
                    boroughMap.set(districtId.id, districtMap);
                    buildingValue.filter(x=>x.districtId== districtId.id && x.boroughId== boroughId.id).forEach(building => {
                        const buildingMap = new Map([['id', building.id]]);
                        districtMap.set(building.id, buildingMap);
                        buildingMap.set('name', building.id + ':' + building.name);
                        const locations = building.location;
                        locations.forEach(location => {
                            const value= locationValue.find(x=>x.id== location);
                            if (value) {
                                const locationMap =new Map([['id', value.id]]);
                                buildingMap.set(value.id, locationMap);
                                locationMap.set('name', value.id + ':' + value.name);
                                this.locations.set(location, value);
                            }
                        });
                    });
                });
            });
            this.$emit('locationsSet', this.locations);

            this.nodes = this.convertMapToArr(nodemap);
            this.loading = false;
            this.emitLoading(false);
        },
        convertMapToArr(map) {
            const nodes = [];
            map.forEach(boroughs => {
                if (boroughs instanceof Map) {
                    const borough = [];
                    boroughs.forEach(districts => {
                        if (districts instanceof Map) {
                            const district = [];
                            districts.forEach(buildings => {
                                if (buildings instanceof Map) {
                                    const building = [];
                                    buildings.forEach(location => {
                                        if (typeof location == 'object') {
                                            building.push({
                                                key: locationTreePrefix.location + location.get('id'),
                                                label: location.get('name'),
                                                selectable: true
                                            });
                                        }
                                    });
                                    district.push({
                                        key: locationTreePrefix.building + buildings.get('id'),
                                        label: buildings.get('name'),
                                        children: building
                                    });
                                }
                            });
                            borough.push({
                                key: locationTreePrefix.district + districts.get('id'),
                                label: districts.get('name'),
                                children: district
                            });
                        }
                    });
                    nodes.push({
                        key: locationTreePrefix.borough + boroughs.get('id'),
                        label: boroughs.get('name'),
                        children: borough
                    });
                }
            });
            return nodes;
        },
        throwDataPullError({ detail = 'Error occured while pulling Location Datas.' }) {
            this.$toast.add({
                severity: 'error',
                closable: false,
                detail: detail,
                life: toasterTime
            });
        },
        emitLoading(loading) {
            this.$emit('loading', loading);
        },
        onClick() {
            const result = {
                boroughs: [],
                districts: [],
                buildings: [],
                locations: []
            };
            const keys = Object.keys(this.selectedKeys);
            keys.forEach(key => {
                const k = key.split('#', 2);
                result[`${ k[0] }s`].push(k[1]);
            });
            this.$emit('selectTree', this.selectedKeys, result);
        },
        async setTreeNodeBuildingManager() {
            this.emitLoading(true);
            this.loading = true;
            const ids = this.userDetails.roleData[USER_ROLE.BUILDING_MANAGER];
            this.visitLocations = await VisitLocationService.getByIds(ids);
            const map = new Map();
            this.visitLocations.forEach(val => {
                const locationMap = map.get(val.boroughId)?.get(val.districtId)?.get(val.buildingId) ?
                    map.get(val.boroughId).get(val.districtId).get(val.buildingId) :
                    new Map();
                locationMap.set('id', val.buildingId);
                locationMap.set('name', val.buildingName);
                val.coveredLocations.forEach(loc => {
                    locationMap.set(loc.locationId, { id: loc.locationId, name: loc.locationName });
                });
                const buildingMap = map.get(val.boroughId)?.get(val.districtId) ? map.get(val.boroughId).get(val.districtId) : new Map();
                buildingMap.set('id', val.districtId);
                buildingMap.set('name', val.districtName);
                buildingMap.set(val.buildingId, locationMap);
                const districtMap = map.get(val.boroughId) ? map.get(val.boroughId) : new Map();
                districtMap.set('id', val.boroughId);
                districtMap.set('name', val.boroughName);
                districtMap.set(val.districtId, buildingMap);
                map.set(val.boroughId, districtMap);
            });
            this.nodes = this.convertMapToArr(map);
            this.loading = false;
            this.emitLoading(false);
        }
    }
};
</script>

<style scoped>
.p-progress-spinner {
    width: 5rem;
    height: 3.125rem;
}
</style>
